import { AppProps, NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Tracker from '../../gtm';
import appInsights, { ApplicationInsights } from '../appInsights';
import './App.css';

export function reportWebVitals(metric: NextWebVitalsMetric) {
  appInsights.trackMetric({ name: metric.name, average: metric.value });
}

const App = ({ Component, pageProps }: AppProps) => {
  const { locale } = useRouter();
  Tracker.getInstance(locale);
  useEffect(() => {
    const pageName = pageProps?.data?.page?.properties?.metaTitle
      ? pageProps?.data?.page?.properties?.metaTitle
      : pageProps?.data?.page?.properties?.header;
    const nodeId = pageProps?.data?.page?.id;
    Tracker.trackPageView(pageName, nodeId);
  }, [
    pageProps?.data?.page?.id,
    pageProps?.data?.page?.properties?.header,
    pageProps?.data?.page?.properties?.metaTitle,
  ]);

  return (
    <ApplicationInsights>
      <Component {...pageProps} />
    </ApplicationInsights>
  );
};

export default App;
